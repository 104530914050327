import { HeadLine } from "../../component";
import HelpBlock from "./HelpBlock";

const index = () => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-800">
      <div className="container">
        <div className="items-center justify-between lg:flex lg:space-x-24">
          <div className="lg:w-[45%]">
            <HeadLine
              text="SUPPORTERS… What's In It For You?"
              classes="font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl uppercase"
            />
            {/* <h2 className="mb-6 font-display text-3xl font-medium text-jacarta-700 dark:text-white">
            SUPPORTERS… WHAT&apos;S IN IT FOR YOU?
            </h2> */}
            {/* <p className="mb-6 text-lg dark:text-jacarta-300">
              WHAT&apos;S IN IT FOR YOU?
            </p> */}
          </div>
          <div className="lg:w-[55%]">
            <HelpBlock />
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
